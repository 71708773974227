const Sites = {
	Museum: 'Museum',
};

const SITE = Sites.Museum;

const SITES_DOMAIN = {
	[Sites.Museum]: "muzeum.ustron.pl"
};

export { Sites, SITE, SITES_DOMAIN };
