import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

const TemporaryExhibitions = (props) => {
    const {history} = props;
    return (
        <>
        TemporaryExhibitions
        </>
    );
}

export default withRouter(TemporaryExhibitions);
